<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
        mdi-file-document
      </v-icon>
    </template>

    <span>Certificaciones</span>

    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="text-h6 grey lighten-2">
            <span class="headline">Certificaciones</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-container fill-height bottom-gradient fluid class="ma-0">
            <v-row>
              <v-col>
                <div class="fill-height">
                  <!--v-model="model"-->
                  <v-slide-group center-active class="pa-0" next-icon="mdi-arrow-right-drop-circle-outline"
                                 prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                    <v-slide-item v-for="itemImage in certificationsEntity" :key="itemImage.id"
                                  v-slot="{ active, toggle }">
                      <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg" height="100"
                              width="100" elevation="5" @click="toggle">
                        <v-img :src="
                                  itemImage.image_certification != null
                                    ? itemImage.image_certification
                                    : require('@/assets/images/no-image.jpg')
                                " :lazy-src="
                                  require('@/assets/images/no-image.jpg')
                                " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="100"
                               class="rounded-lg white--text align-end justify-start text-end elevation-10">
                          <a target="_blank" class="a-custom" :href="itemImage.pivot.url">
                            <v-icon class="i-custom">mdi-file-document</v-icon>
                          </a>
                          <v-btn dark icon elevation="0" class="ma-1" v-on:click="deleteItem(itemImage)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>

                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="5">
                  <v-date-picker v-model="expire" :rules="rules" :disabled="saveChanges"></v-date-picker>
                </v-col>
                <v-col cols="7">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          v-model="certification"
                          :items="items"
                          :disabled="saveChanges"
                          :rules="rules"
                          item-value="id"
                          item-text="name"
                          outlined
                          label="Certificaciones"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                          :rules="rules"
                          show-size
                          small-chips
                          :disabled="saveChanges"
                          label="Documento"
                          truncate-length="50"
                          v-model="file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="saveChanges" text @click="close">
              Cancelar
            </v-btn>
            <v-btn :disabled="!valid || saveChanges" color="primary" :loading="saveChanges"
                   @click="saveCertifications">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-tooltip>
</template>

<script>
import axios from "axios";
export default {
  name: "Certifications",
  props: ["entity", "url", "deleteUrl"],
  data(){
    return {
      dialog: false,
      keywords: "",
      certifications: [],
      certificationsEntity: [],
      file: null,
      certification: null,
      expire: new Date(),
      saveChanges: false,
      rules: [
        (v) => !!v || 'El campo es requerido'
      ],
      valid: false,
    }
  },

  mounted(){
    this.certificationsEntity = this.entity.certifications??[];
    this.expire = this.defaultDate;
    this.getCertifications();
  },

  computed: {
    items(){
      return this.certifications.filter(e=>!this.certificationsEntity.find(f=>f.id===e.id));
    },
    defaultDate(){
      const today = new Date();
      const day = today.getDate() < 10? `0${today.getDate()}`: today.getDate();
      const month = today.getMonth() < 9 ? `0${today.getMonth() + 1}`: today.getMonth() + 1;
      return `${today.getFullYear()}-${month}-${day}`;
    }
  },

  methods: {
    deleteItem(item){
      axios.post(this.deleteUrl, {
            id: this.entity.id,
            certification: item.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          }
      ).then(resp=>{
        if(resp.status==200){
          this.certificationsEntity = this.certificationsEntity.filter(e=>e.id != item.id);
        }
      }).catch(err=>console.log(err));
    },
    getCertifications(){
      axios.get(`${process.env.VUE_APP_BHC_BASE_URL}/view-list/certifications`).then(resp=>{
        this.certifications = resp.data.value;
      })
    },
    openDialog(){
      this.certificationsEntity = this.entity.certifications??[];
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = true;
      this.valid = false;
    },

    close(){
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = false;
      this.valid = false;
    },

    reset(){
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.valid = false;
      this.dialog = false;
      this.$emit('reload');
    },

    saveCertifications(){
      this.$refs.form.validate()
      if(this.$refs.form.validate(true)){
        this.saveChanges = true;
        const formData = new FormData();
        formData.append("id", this.entity.id);
        formData.append("certification", this.certification);
        formData.append("file", this.file);
        formData.append("expire", this.expire);
        axios.post(this.url, formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
            }
        ).then(resp=>{
          if(resp.data.success){
            this.certificationsEntity = [...this.certificationsEntity, resp.data.value];
            this.reset();
          }
        }).catch(err=>console.log(err)).finally(()=>{
          this.saveChanges = false;
        });
      }
    }
  }
}
</script>

<style>
.a-custom{
  margin-right: 1.6rem;
  text-decoration: none;
}
.i-custom{
  color: #fff !important;
  font-size: 20px !important;
}
</style>