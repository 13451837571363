<template>
  <clinics-module-component />
</template>

<script>
import ClinicsModuleComponent from "@/components/admin/modules/ClinicsModuleComponent";

export default {
  name: "ClinicsModule",
  title: "Gestión de Clínicas | Baja California Health Tourism",
  components: { ClinicsModuleComponent },
};
</script>

<style scoped></style>
